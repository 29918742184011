import { render, staticRenderFns } from "./signup-result-page.vue?vue&type=template&id=0f52ce78&scoped=true&lang=pug&"
import script from "./signup-result-page.vue?vue&type=script&lang=ts&"
export * from "./signup-result-page.vue?vue&type=script&lang=ts&"
import style0 from "./signup-result-page.vue?vue&type=style&index=0&id=0f52ce78&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f52ce78",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VCard,VImg})
