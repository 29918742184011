



























import Vue from "@/vue-ext";
import { TranslateResult } from "vue-i18n";
import { Component } from "vue-property-decorator";
import Layout from "../components/layouts/blank-layout.vue";

@Component({
  components: {
    Layout,
  },
})
export default class SignupResultPage extends Vue {
  appVersion = process.env.VUE_APP_VERSION;

  get message(): TranslateResult {
    return this.isSuccess
      ? this.successMessage
      : this.$t(
        "Your payment was unsuccessful. You can either try again with a different payment method, check your braintree or stripe account, or call your bank."
      );
  }

  get successMessage(): TranslateResult {
    return this.corporateAccountFlow
      ? this.$t("Thank you for choosing Aware24!")
      : this.$t("Your payment was successful.");
  }

  get corporateAccountFlow(): boolean {
    return Boolean(this.$route.query.corporate);
  }

  get isSuccess(): boolean {
    return !this.$route.query.fail;
  }

  get email(): string {
    return this.$route.query.email as string;
  }

  back(): void {
    location.href = this.$baseWebsite;
  }
}
